

















import AutoNumericUtil from "@/utilities/AutoNumericUtil";
import { Options } from "autonumeric";
import { Vue, Component, Prop } from "vue-property-decorator";
import { SystemSettingInfoProperties } from "./SystemSettingInfoView.vue";

@Component({})
export default class SystemSettingVoucherView extends Vue {
    @Prop() private properties: SystemSettingInfoProperties;
    private inputVoucherLength: HTMLInputElement;

    public get systemSetting() {
        return this.properties.systemSetting;
    }
    public get voucher() {
        return this.systemSetting.voucher;
    }

    public mounted() {
        this.properties.events.subscribe('update-formatter', this.updateFormatter);
        this.inputVoucherLength = this.$el.querySelector('#voucher-length');

        this.updateFormatter();
    }

    public destroyed() {
        this.properties.events.remove('update-formatter', this.updateFormatter);
    }

    public updateFormatter() {
        setTimeout(() => {
            const options: Options = {
                minimumValue: "0",
                historySize: 0,
                decimalPlaces: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false
            };

            AutoNumericUtil.findOrNew(this.inputVoucherLength, null, options)
                .set(this.voucher.voucherLength ?? 0);
        }, 150);
    }
}
